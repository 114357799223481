import { Nullable } from '@dots/uikit'
import { API_LIST } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { OrderCommercialOfferModel } from '@share/schemas'
import { useMutation } from '@tanstack/react-query'

type AlternativeProduct = {
  productId: string
  price: number
}

type AdditionalService = {
  productAdditionalServiceId: string
  price: number
}

type Product = {
  orderProductId: string
  price: number
  packageIndex: number
  vendorId: string
  quantity: number
  additionalServices: AdditionalService[]
  alternativeProducts: AlternativeProduct[]
}

type Comment = {
  title?: string
  content?: string
  keepPrevious?: boolean
}

export type CreateCommercialOfferRequest = {
  currencyId: Nullable<string>
  validUntil: Nullable<string>
  etaMinDays: Nullable<number>
  etaMaxDays: Nullable<number>
  vendorId?: string
  tax: Nullable<number>
  shippingPrice: Nullable<number>
  shipmentCost: Nullable<number>
  comment?: Nullable<Comment>
  commercialOfferProducts: Product[]
}

type CreateCommercialOfferRequestResponse = OrderCommercialOfferModel

const createCommercialOfferRequest = async (data: CreateCommercialOfferRequest) =>
  apiRequest<CreateCommercialOfferRequestResponse>({
    method: 'POST',
    url: API_LIST.commercialOffer.root,
    data,
  })

export const useCreateCommercialOfferRequest = () => useMutation(createCommercialOfferRequest)
