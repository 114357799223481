import { Nullable, Undefinable } from '@share/@types'
import { DeliveryOrderStatus, OrderStatus, OrderSubtype } from '@share/schemas'
import { isDef } from '@share/utils'

import { ORDER_PENDING_STATUSES, ORDER_SUBTYPE_SUBTITLES } from './constants'

type ActualStatus = DeliveryOrderStatus | OrderStatus

export const getIsOrderCanEdit = (orderStatus: ActualStatus) =>
  orderStatus === 'PROCESSING' || orderStatus === 'PENDING_OFFER'

export const getIsOrderCanEditPayment = (orderStatus: ActualStatus) =>
  getIsOrderCanEdit(orderStatus) ||
  orderStatus === 'PICKING_UP' ||
  orderStatus === 'SHIPPING' ||
  orderStatus === 'DELIVERED' ||
  orderStatus === 'CONFIRMED'

export const getIsOrderCanEditPONumber = (orderStatus: ActualStatus) =>
  orderStatus === 'PREPARING' ||
  orderStatus === 'PICKING_UP' ||
  orderStatus === 'SHIPPING' ||
  orderStatus === 'DELIVERED' ||
  orderStatus === 'CONFIRMED'

export const getIsOrderExpired = (orderStatus: ActualStatus) => orderStatus === 'EXPIRED'

export const getIsOrderCanViewPONumber = (orderStatus: ActualStatus) =>
  orderStatus === 'PREPARING' ||
  orderStatus === 'PICKING_UP' ||
  orderStatus === 'SHIPPING' ||
  orderStatus === 'DELIVERED' ||
  orderStatus === 'CONFIRMED' ||
  orderStatus === 'PENDING_PAYMENT' ||
  orderStatus === 'COMPLETED' ||
  orderStatus === 'IN_DISPUTE'

export const getIsOrderCanDeliveryActions = (orderStatus: ActualStatus) =>
  orderStatus === 'DELIVERED'

export const getIsOrderShowTotalPrice = (orderStatus: ActualStatus) =>
  orderStatus === 'PENDING_OFFER' ||
  orderStatus === 'PREPARING' ||
  orderStatus === 'SHIPPING' ||
  orderStatus === 'DELIVERED' ||
  orderStatus === 'CONFIRMED' ||
  orderStatus === 'PENDING_PAYMENT' ||
  orderStatus === 'COMPLETED' ||
  orderStatus === 'IN_DISPUTE'

export const getIsOrderShowCommercialOffer = (orderStatus: ActualStatus) =>
  orderStatus === 'PENDING_OFFER'

export const getIsShowPrice = (status: ActualStatus) => status !== 'PROCESSING'

export const getIsOrderPending = (status: ActualStatus) => ORDER_PENDING_STATUSES.includes(status)

export const getOrderSubtypeLabel = (orderSubtype: Undefinable<OrderSubtype>) =>
  ORDER_SUBTYPE_SUBTITLES[orderSubtype || 'PURCHASE']

export const getOrderTrackingNumber = (
  trackingNumber: Nullable<string>,
  orderStatus: ActualStatus,
) => {
  if (getIsOrderExpired(orderStatus)) return '-'
  if (getIsOrderPending(orderStatus)) return 'Pending'

  return isDef(trackingNumber) ? trackingNumber : undefined
}
