import { memo } from 'react'
import { Accordion, Checkbox, List, Typography } from '@share/components/ui'
import { AvailableFilters } from '@share/hooks'
import { SelectedFilters } from '@share/utils'

interface FiltersItemsProps {
  list?: AvailableFilters[]
  selectedFilters: SelectedFilters[]
  onChangeFilter: (filter: { name: string; value: string }) => void
}

export const FiltersItems: React.FC<FiltersItemsProps> = memo(
  ({ list = [], selectedFilters, onChangeFilter }) => {
    const filtersList = list.map(({ name, outputName, specDetailValues }) => {
      const listItems = specDetailValues.map((value) => {
        const getSameFilter = selectedFilters.find((filter) => filter.name === name)
        const isChecked = Boolean(getSameFilter?.value.includes(value))

        const onCheckedChange = () => {
          onChangeFilter({
            name,
            value,
          })
        }

        return {
          id: value,
          content: (
            <Checkbox
              key={value}
              label={value}
              value={value}
              checked={isChecked}
              onCheckedChange={onCheckedChange}
            />
          ),
        }
      })

      return {
        value: outputName,
        title: (
          <Typography component='span' variant='b1'>
            {outputName}
          </Typography>
        ),
        content: <List list={listItems} />,
      }
    })

    const defaultOpenedFilters = filtersList.map((filter) => filter?.value)

    return <Accordion type='multiple' list={filtersList} defaultValue={defaultOpenedFilters} />
  },
)

FiltersItems.displayName = 'FiltersItems'
