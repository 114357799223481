import { ClassName } from '@share/@types'
import cn from 'classnames'

import s from './list.module.scss'

type ListItem = {
  id: React.Key
  content: React.ReactNode
}

export interface ListProps extends ClassName {
  list: Array<ListItem>
  withSearch?: boolean
  maxItems?: number
}

export const List: React.FC<ListProps> = ({ list, className, withSearch }) => (
  <div className={cn(s.root, className)}>
    {withSearch && (
      <div className={s.search}>
        <input type='text' className={s.searchInput} />
      </div>
    )}

    <ul className={s.list}>
      {list.map(({ id, content }) => (
        <li key={id} className={s.item}>
          {content}
        </li>
      ))}
    </ul>
  </div>
)
