import { Group, Stack, Typography } from '@dots/uikit'
import type { AssetPreviewProps, PictureBox } from '@share/components/ui/atoms'
import { AssetPreview } from '@share/components/ui/atoms'
import { withHoverEffect } from '@share/hocs'

export interface AssetBoxProps extends AssetPreviewProps {
  title: PictureBox.CaptionProps['title']
  subtitle?: PictureBox.CaptionProps['subtitle']
  onClick?: () => void
  titleClassname?: string
  actionSlot?: React.ReactNode
  titleTestId?: string
  subtitleTestId?: string
}

export const AssetBox: React.FC<AssetBoxProps> = ({
  title,
  subtitle,
  image,
  category,
  onClick,
  titleClassname,
  actionSlot,
  titleTestId,
  subtitleTestId,
  ...rest
}) => (
  <Group gap={16} align='middle' onClick={onClick} {...rest}>
    <AssetPreview image={image} category={category} />

    <Stack fullWidth>
      <Typography component='span' variant='c2' className={titleClassname} dataTestId={titleTestId}>
        {title}
      </Typography>

      {subtitle && (
        <Typography
          component='span'
          variant='b3'
          className='fcSecondary'
          dataTestId={subtitleTestId}
        >
          {subtitle}
        </Typography>
      )}
    </Stack>

    {actionSlot}
  </Group>
)

export const AssetBoxWithHoverEffect = withHoverEffect(AssetBox)
