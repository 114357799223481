import * as yup from 'yup'

export const updateModelSchema = yup.object({
  id: yup.string().defined(),
  title: yup.string().defined(),
  text: yup.string().defined(),
  link: yup.string().defined().nullable(),
})

export type UpdateModel = yup.InferType<typeof updateModelSchema>
export type UpdateMutationModel = Pick<UpdateModel, 'title' | 'text' | 'link'>
